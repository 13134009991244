import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"
import ThemeProvider from "@material-ui/styles/ThemeProvider"
import theme from "../theme"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"

const GeneralitiesByPass = () => (
  <React.Fragment>
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="Généralités Bypass" />
        <Typography
          variant="h4"
          color="inherit"
          style={{ margin: "20px 0px 0px" }}
        >
          By-pass gastrique
        </Typography>
        <Typography
          variant="h6"
          color="textSecondary"
          style={{ margin: "8px 0px 20px" }}
        >
          Technique restrictive et malabsorptive
        </Typography>
        <Image alt="Bypass" filename="bypass.png" />
        <Typography
          variant="caption"
          color="inherit"
          style={{ textAlign: "center", display: "block", marginBottom: 20 }}
        >
          <a href="https://www.has-sante.fr/upload/docs/application/pdf/2009-09/fiche_technique_bypass_080909.pdf">
            <i>
              https://www.has-sante.fr/upload/docs/application/pdf/2009-09/fiche_technique_bypass_080909.pdf
            </i>
          </a>
        </Typography>
        <Typography variant="body1">
          Cette technique permet de diminuer à la fois la quantité d’aliments
          ingérés (réduction de la taille de l’estomac) et l’assimilation de ces
          aliments par l’organisme, grâce à un court-circuit d’une partie de
          l’estomac et du duodénum. Les aliments vont directement dans le
          jéjunum, et sont donc assimilés en moindres quantités.
        </Typography>
        <div style={{ width: "100%", marginTop: 20, textAlign: "center" }}>
          <Button href="/generalities">Revenir aux Généralités</Button>
        </div>
      </Layout>
    </ThemeProvider>
  </React.Fragment>
)

export default GeneralitiesByPass
